import './scss/style.scss'
import '@fortawesome/fontawesome-free/js/all.js'

/* $.ajax({
    method: "POST",
    url: "ajax/mail.php",
    data: { data },
    beforeSend : function(){
        
    }
}).done(function(resultado){
    alert(1)
}); */

$(document).on('click', 'a.row-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top -200
    }, 1000);
});